export const configFormData = {
  defaultFontFamily: [],
  defaultFields: {
    description: '',
    fontFamily: [],
    sequenceNo: 0,
    usage: ''
  },
  defaultSize: {
    psdFormats: ['PSD'],
    psdFormat: 'PSD',
    psdResolution: 300,
    psdWidth: 760,
    psdMinHeight: 800,
    psdMaxHeight: 30000,
    jpgFormat: 'JPG',
    jpgResolution: 72,
    jpgWidth: 760,
    jpgMinHeight: 800,
    jpgMaxHeight: 4200
  },
  defaultCoverImage: {
    thumbnailImageWidth: 1280,
    thumbnailImageHeight: 720,
    thumbnailImageFormat: 'PNG',
    thumbnailImageMaxSize: 5
  },
  defaultOutlineWidth: [
    {
      width: 3
    },
    {
      width: 4
    },
    {
      width: 6
    },
    {
      width: 8
    }
  ],
  defaultOutlineColor: [
    {
      color: '#000000'
    },
    {
      color: '#ffffff'
    }
    // {
    //   color:"#ff0000"
    // },
  ],
  classIds: [
    {
      label: '注音屏蔽字词',
      value: 17
    },
    {
      label: '表记',
      value: 18
    },
    {
      label: '推荐用字',
      value: 16
    },
    {
      label: '字符设置',
      value: 1
    },
    {
      label: '注音设置',
      value: 15
    },
    {
      label: '文本',
      value: 2
    },
    {
      label: '屏蔽词',
      value: 3
    },
    {
      label: '敏感词',
      value: 11
    },
    {
      label: '混淆词',
      value: 12
    },
    {
      label: '词汇',
      value: 13
    },
    {
      label: '字库',
      /* NOT_DICTIONARY */
      value: 10
    },
    {
      label: '字体设置',
      value: 4
    },
    {
      label: '尺寸设置',
      value: 5
    },
    {
      label: '章节封面尺寸设置',
      value: 6
    },
    {
      label: '专有词汇',
      value: 7
    },
    {
      label: 'PDF文件',
      value: 8
    },
    {
      label: '外链',
      value: 9
    }
  ],
  typeIds: [
    {
      label: '翻译设定',
      value: 1
    },
    {
      label: '规章制度',
      value: 2
    },
    {
      label: '教程',
      value: 3
    },
    {
      label: '词汇',
      value: 4
    },
    {
      label: '评级及结算规则',
      value: 5
    }
  ],
  textLayout: [
    {
      value: 1,
      label: '水平'
    },
    {
      value: 2,
      label: '垂直'
    }
  ],
  textAlign: [
    {
      value: 'left',
      label: '左对齐'
    },
    {
      value: 'center',
      label: '居中对齐'
    },
    {
      value: 'right',
      label: '右对齐'
    }
  ],
  antiAliasMethod: [
    {
      value: 'antiAliasStrong',
      label: '浑厚'
    },
    {
      value: 'antiAliasSharp',
      label: '锐利'
    },
    {
      value: 'antiAliasCrisp',
      label: '犀利'
    },
    {
      value: 'antiAliasSmooth',
      label: '平滑'
    }
  ],
  lineHeight: [
    {
      label: '10%',
      value: 0.1
    },
    {
      label: '20%',
      value: 0.2
    },
    {
      label: '30%',
      value: 0.3
    },
    {
      label: '40%',
      value: 0.4
    },
    {
      label: '50%',
      value: 0.5
    },
    {
      label: '60%',
      value: 0.6
    },
    {
      label: '70%',
      value: 0.7
    },
    {
      label: '80%',
      value: 0.8
    },
    {
      label: '90%',
      value: 0.9
    },
    {
      label: '100%',
      value: 1
    },
    {
      label: '110%',
      value: 1.1
    },
    {
      label: '115%',
      value: 1.15
    },
    {
      label: '120%',
      value: 1.2
    },
    {
      label: '130%',
      value: 1.3
    },
    {
      label: '150%',
      value: 1.5
    },
    {
      label: '200%',
      value: 2
    }
  ],

  scaleX: [
    {
      label: '10%',
      value: 0.1
    },
    {
      label: '20%',
      value: 0.2
    },
    {
      label: '30%',
      value: 0.3
    },
    {
      label: '40%',
      value: 0.4
    },
    {
      label: '50%',
      value: 0.5
    },
    {
      label: '60%',
      value: 0.6
    },
    {
      label: '70%',
      value: 0.7
    },
    {
      label: '80%',
      value: 0.8
    },
    {
      label: '90%',
      value: 0.9
    },
    {
      label: '100%',
      value: 1
    },
    {
      label: '110%',
      value: 1.1
    },
    {
      label: '120%',
      value: 1.2
    },
    {
      label: '130%',
      value: 1.3
    }
  ],
  scaleY: [
    {
      label: '10%',
      value: 0.1
    },
    {
      label: '20%',
      value: 0.2
    },
    {
      label: '30%',
      value: 0.3
    },
    {
      label: '40%',
      value: 0.4
    },
    {
      label: '50%',
      value: 0.5
    },
    {
      label: '60%',
      value: 0.6
    },
    {
      label: '70%',
      value: 0.7
    },
    {
      label: '80%',
      value: 0.8
    },
    {
      label: '90%',
      value: 0.9
    },
    {
      label: '100%',
      value: 1
    },
    {
      label: '110%',
      value: 1.1
    },
    {
      label: '120%',
      value: 1.2
    },
    {
      label: '130%',
      value: 1.3
    }
  ],
  fontSize: [
    {
      label: 4,
      value: 17,
    },
    {
      label: 5,
      value: 21,
    },
    {
      label: 6,
      value: 25,
    },
    {
      label: 7,
      value: 29
    },
    {
      label: 7.5,
      value: 31
    },
    {
      label: 8,
      value: 33,
    },
    {
      label: 8.5,
      value: 35,
    },
    {
      label: 9,
      value: 37,
    },
    {
      label: 9.5,
      value: 39,
    },
    {
      label: 10,
      value: 41,
    },
    {
      label: 11,
      value: 45,
    },
    {
      label: 12,
      value: 49,
    },
    {
      label: 14,
      value: 57,
    },
    {
      label: 18,
      value: 73,
    },
    {
      label: 24,
      value: 97,
    },
    {
      label: 30,
      value: 121,
    },
    {
      label: 36,
      value: 145,
    },
    {
      label: 48,
      value: 197,
    },
    {
      label: 60,
      value: 241,
    },
    {
      label: 72,
      value: 289,
    },
  ]
}
